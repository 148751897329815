import { render, staticRenderFns } from "./minting-count-down.vue?vue&type=template&id=3d070884&scoped=true&"
import script from "./minting-count-down.vue?vue&type=script&lang=ts&"
export * from "./minting-count-down.vue?vue&type=script&lang=ts&"
import style0 from "./minting-count-down.vue?vue&type=style&index=0&id=3d070884&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d070884",
  null
  
)

export default component.exports