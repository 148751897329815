






















































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'
@Observer
@Component({
  components: {},
})
export default class CountDown extends Vue {
  @Inject({}) vm!: MintingViewModel
}
